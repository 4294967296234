import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const CeoSectionWhite = () => {
  return (
    <section id="ceo">
      <div>
        <StaticImage
          className="img"
          src="../../images/new-web/ceo02.jpg"
          alt="CEO"
          placeholder="blurred"
        />
      </div>
      <div>
        <h2>
          Slovo od CEO
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>
        <p>
          Radi spolupracujeme so spoločnosťami, ktoré majú ambíciu napredovať a
          zlepšovať svoje fungovanie. Ak je to aj váš prípad, budeme radi ak sa
          stanete našim partnerom.
        </p>
        <p>Martin Tóth</p>
      </div>
    </section>
  );
};

export default CeoSectionWhite;
