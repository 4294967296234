import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const GoalSection = () => {
  return (
    <section id="goal">
      <div>
        <h3>
          Neustále stanovujeme nové ciele a neúnavne pracujeme na tom, aby sme
          vaše plány premenili na realitu. Veríme, že kľúčom k spoločnému rastu
          sú
        </h3>
        <ul>
          <li>• Dôvera</li>
          <li>• Partnerstvo</li>
          <li>• Komunikácia</li>
          <li>• Neustále vzdelávanie</li>
          <li>• Kvalita pred kvantitou</li>
        </ul>
      </div>
      <div>
        <StaticImage
          className="img"
          src="../../images/new-web/goal.jpg"
          alt="Náše ciele"
          placeholder="blurred"
        />
      </div>
    </section>
  );
};

export default GoalSection;
