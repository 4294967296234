import React from "react"




const EthicsDot = (props) => {



    return (
        <li className={ (props.inView) ? "active" : "" } >{props.text}</li>
    )
}

export default EthicsDot;