import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AboutSection = () => {
  return (
    <section id="about">
      <div>
        <StaticImage
          className="img"
          src="../../images/new-web/about.jpg"
          alt="Prototypovanie"
          placeholder="blurred"
        />
      </div>
      <div>
        <h3>
          Už od roku 2014 vám pomáhame na ceste za úspechom
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h3>
        <p>
          Našim poslaním je pomáhať vám plniť si sny a plány pomocou digitálnych
          riešení. Či je to Webstránka, Eshop, Informačný systém, B2B portál
          alebo Aplikácia, kryjeme vám chrbát. Radi pre vás vytvárame užitočné a
          jednoduché riešenia za dostupné ceny a poskytujeme kvalitný servis s
          dobrou komunikáciou.
        </p>
      </div>
    </section>
  );
};

export default AboutSection;
