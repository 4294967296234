import { Link } from "gatsby";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const MainSectionOSpolocnosti = () => {
  return (
    <section id="o-spolocnosti">
      <div className="banner">
        <h1>
          Sme IT nadšenci, ktorí <br /> vám pomáhajú napredovať
        </h1>
        <StaticImage
          className="arrow-down"
          src="../../images/new-web/arrow.svg"
          alt="Arrow"
          placeholder="blurred"
        />
      </div>
      <div className="animation-5 image">
        <svg
          width="100%"
          height="100%"
          viewBox="100 10 350 350"
          fill="none"
          style={{ overflow: "visible" }}
        >
          <g id="background_01">
            <path
              id="circle-dashed-large_05"
              opacity="0.9"
              d="M174.362 391.633C224.764 460.977 321.836 476.334 391.18 425.933C460.525 375.532 475.881 278.459 425.48 209.115C375.079 139.77 278.006 124.414 208.662 174.815C139.318 225.216 123.961 322.288 174.362 391.633Z"
              stroke="#0080CB"
              stroke-width="1.87646"
              stroke-dasharray="0.63 0.63"
            />
            <g id="circle-dashed-medium_05" opacity="0.9">
              <path
                id="Vector_2"
                opacity="0.9"
                d="M206.163 369.072C244.023 421.161 316.941 432.696 369.03 394.837C421.119 356.977 432.654 284.059 394.794 231.97C356.935 179.881 284.017 168.345 231.928 206.205C179.839 244.064 168.303 316.982 206.163 369.072Z"
                stroke="#0080CB"
                stroke-width="1.87646"
                stroke-dasharray="0.63 0.63"
              />
            </g>
            <path
              id="circle-large_05"
              d="M155.98 404.659C213.788 484.194 325.125 501.807 404.66 444C484.194 386.192 501.807 274.855 443.999 195.32C386.192 115.786 274.854 98.1726 195.32 155.98C115.786 213.787 98.1728 325.125 155.98 404.659Z"
              stroke="#0036CB"
              stroke-width="1.87646"
            />
            <g id="cross_05">
              <path
                id="Line 1"
                d="M321.612 228.937H357.154"
                stroke="#0021C9"
                stroke-width="1.87625"
              />
              <path
                id="Line 2"
                d="M339.384 210.343V245.885"
                stroke="#0021C9"
                stroke-width="1.87625"
              />
            </g>
            <path
              id="line-3_05"
              d="M446.851 274.437L490.069 300.406"
              stroke="#0021C9"
              stroke-width="1.87625"
            />
            <path
              id="rect-bottom_05"
              d="M280.686 392.466H249.969V423.182H280.686V392.466Z"
              fill="#0021C9"
            />
            <path
              id="rect-top_05"
              d="M277.243 247.666H249.98V275.869H277.243V247.666Z"
              fill="#0080CB"
            />
            <path
              id="arrow_05"
              d="M175.676 280.56L217.749 300.132V312.615L175.676 332.187V318.83L205.008 306.399L175.676 293.916V280.56Z"
              fill="#0080CB"
              stroke="#0080CB"
              stroke-width="0.625415"
              stroke-miterlimit="10"
            />
            <path
              id="circle-filled-medium_05"
              d="M420.607 258.001C431.249 258.001 439.876 249.163 439.876 238.261C439.876 227.359 431.249 218.522 420.607 218.522C409.965 218.522 401.337 227.359 401.337 238.261C401.337 249.163 409.965 258.001 420.607 258.001Z"
              fill="#0080CB"
            />
            <path
              id="circle-filled-small-right_05"
              d="M425.45 181.131C429.608 181.131 432.979 177.731 432.979 173.537C432.979 169.343 429.608 165.943 425.45 165.943C421.291 165.943 417.92 169.343 417.92 173.537C417.92 177.731 421.291 181.131 425.45 181.131Z"
              fill="#0021C9"
            />
            <path
              id="circle-filled-small-left_05"
              d="M181.545 210.208C185.704 210.208 189.075 206.808 189.075 202.614C189.075 198.42 185.704 195.02 181.545 195.02C177.387 195.02 174.016 198.42 174.016 202.614C174.016 206.808 177.387 210.208 181.545 210.208Z"
              fill="#0080CB"
            />
            <path
              id="rect-line_05"
              d="M238.206 261.902H288.626"
              stroke="#0021C9"
              stroke-width="1.87625"
            />
            <path
              id="line-2_05"
              d="M296.009 166.954H312.968"
              stroke="#0021C9"
              stroke-width="1.87625"
            />
            <path
              id="circle-filled-large_05"
              d="M339.472 365.225C354.01 365.225 365.795 353.651 365.795 339.373C365.795 325.095 354.01 313.52 339.472 313.52C324.934 313.52 313.149 325.095 313.149 339.373C313.149 353.651 324.934 365.225 339.472 365.225Z"
              fill="#0021C9"
            />
            <path
              id="circle-line_05"
              d="M338.894 287.891V338.311"
              stroke="#00A2FF"
              stroke-width="1.87625"
            />
            <path
              id="line-1_05"
              d="M296.114 372.706V423.126"
              stroke="#0021C9"
              stroke-width="1.87625"
            />
          </g>
        </svg>
      </div>
    </section>
  );
};

export default MainSectionOSpolocnosti;
