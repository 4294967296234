import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import PartnerBox from "./partnerBox";

const PartnersSectionBlack = () => {
  const data = useStaticQuery(graphql`
    query {
      allMysqlProjects(sort: { fields: case_study_rok, order: DESC }) {
        nodes {
          url
          real_url
        }
        totalCount
      }
      logos: allFile(
        filter: {
          name: { eq: "logo" }
          sourceInstanceName: { eq: "images-projects" }
        }
      ) {
        nodes {
          name
          relativeDirectory
          publicURL
        }
      }
      logosWhite: allFile(
        filter: {
          name: { eq: "logo-white" }
          sourceInstanceName: { eq: "images-projects" }
        }
      ) {
        nodes {
          name
          relativeDirectory
          publicURL
        }
      }
    }
  `);

  //premapovanie obrázkov
  const logos = {};
  data.logosWhite.nodes.map((node) => {
    let str = node.relativeDirectory;
    let index = str.replace("projects/", "") + "-white";
    logos[index] = node.publicURL;
  });

  data.logos.nodes.map((node) => {
    let str = node.relativeDirectory;
    let index = str.replace("projects/", "");
    logos[index] = node.publicURL;
  });

  const projectCount = data.allMysqlProjects.totalCount;
  var fill = <></>;
  if (projectCount % 3 != 0) {
    for (var i = 0; i < 3 - (projectCount % 3); i++) {
      fill = (
        <>
          {fill}
          <a className="filler"></a>
        </>
      );
    }
  }

  return (
    <section id="partners">
      <h3>
        Spolupráce
        <StaticImage
          className="img"
          src="../../images/new-web/arrow.svg"
          alt="Šípka vpravo"
          placeholder="blurred"
        />
      </h3>
      <div className="partners">
        {data.allMysqlProjects.nodes.map((node) => (
          <PartnerBox
            realUrl={node.real_url}
            logo={logos[node.url]}
            logoWhite={logos[node.url + "-white"]}
          />
        ))}

        {fill}
      </div>
    </section>
  );
};

export default PartnersSectionBlack;
