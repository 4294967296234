import React from "react"

//above-the-fold
import Layout from "../components/layout"
import SEO from "../components/seo"
import MainSectionOSpolocnosti from "../components/o-spolocnosti/mainSection.js"

//belove-the-fold
import "../styles/o-spolocnosti.scss"
import AboutSection from "../components/o-spolocnosti/aboutSection";
import EthicsSection from "../components/o-spolocnosti/ethicsSection";
import GoalSection from "../components/o-spolocnosti/goalSection";
import PartnersSectionBlack from "../components/o-spolocnosti/partnersSectionBlack";
import CeoSectionWhite from "../components/o-spolocnosti/ceoSectionWhite";
import ContactUs from "../components/contactUs"




const SpolocnostPage = () => (
  <Layout pageName="o-spolocnosti-main">
    <SEO 
      title="O nás" 
      description="Svoje vedomosti neustále obohacujeme, aby sme vám pri tvorbe web stránok prinášali len tie najnovšie technológie." 
    />
    <MainSectionOSpolocnosti />
    <AboutSection />
    <EthicsSection />
    <GoalSection />
    <PartnersSectionBlack />
    <CeoSectionWhite />
    <ContactUs 
      text={<>Neviete, ktorá služba bude najlepšia pre vás?<br/> Poradíme vám!</>} 
      button="Poraďte sa s nami hneď"
    />
  </Layout>
)

export default SpolocnostPage
