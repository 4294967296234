import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import useImportScript from "../useImportScript";
import EthicsDot from "./ethicsDot";
import { useInView } from "react-cool-inview";

const EthicsSection = () => {
  useImportScript(
    "https://cdnjs.cloudflare.com/ajax/libs/animejs/3.2.1/anime.min.js",
    () => {
      const anime = window.anime;

      var path1_02 = anime.path("#line-long_02"),
        path2_02 = anime.path("#circle-large_02 path"),
        path3_02 = anime.path("#circle-dashed-extralarge_02 path");

      anime({
        targets: "#line-4_02",
        translateX: path1_02("x"),
        translateY: path1_02("y"),
        direction: "alternate",
        rotate: ["48deg", "408deg"],
        easing: "easeInOutQuad",
        duration: 25000,
        loop: true,
      });

      anime({
        targets: "#line-2_02",
        translateX: path2_02("x"),
        translateY: path2_02("y"),
        rotate: path2_02("angle"),
        direction: "normal",
        easing: "linear",
        duration: 30000,
        loop: true,
      });

      anime({
        targets: "#circle-filled-large_02",
        translateX: path2_02("x"),
        translateY: path2_02("y"),
        rotate: path2_02("angle"),
        direction: "reverse",
        easing: "linear",
        duration: 40000,
        loop: true,
      });

      anime({
        targets: "#circle-filled-medium_02",
        translateX: path3_02("x"),
        translateY: path3_02("y"),
        rotate: path3_02("angle"),
        direction: "normal",
        easing: "linear",
        duration: 45000,
        loop: true,
      });
    }
  );

  const [count, setCount] = useState(0);

  const { observe, inView } = useInView({
    threshold: [0.6, 0.7, 0.8, 0.9, 1],
    //threshold: [0.7, 0.775, 0.85,0.925, 1],
    onChange: ({ scrollDirection }) => {
      let direction = scrollDirection.vertical;
      if (direction == "down" && count > 0) {
        setCount(count - 1);
      } else if (direction == "up" && count < 5) {
        setCount(count + 1);
      }
    },
  });

  return (
    <section id="ethics" ref={observe}>
      <div>
        <h2>
          Očakávajte
          <StaticImage
            className="img"
            src="../../images/new-web/arrow.svg"
            alt="Šípka vpravo"
            placeholder="blurred"
          />
        </h2>
        <div className="vl"></div>
        <ul>
          <EthicsDot
            text="Jednoduché riešenia"
            inView={count > 0 ? true : false}
          />
          <EthicsDot text="Dostupné ceny" inView={count > 1 ? true : false} />
          <EthicsDot text="Kvalitný servis" inView={count > 2 ? true : false} />
          <EthicsDot
            text="Dobrú komunikáciu"
            inView={count > 3 ? true : false}
          />
          <EthicsDot
            text="Priateľsky prístup"
            inView={count > 4 ? true : false}
          />
        </ul>
      </div>
      <div className="animation-2 image">
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 600 600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="list_02">
            <g id="circle-dashed-extralarge_02" opacity="0.9">
              <path
                id="Vector"
                opacity="0.9"
                d="M161.233 419.34C194.197 464.693 257.685 474.736 303.038 441.773C348.391 408.809 358.434 345.321 325.471 299.968C292.507 254.615 229.019 244.572 183.666 277.535C138.313 310.499 128.27 373.987 161.233 419.34Z"
                stroke="#0080CB"
                stroke-width="4.01349"
                stroke-dasharray="1.34 1.34"
              />
            </g>
            <g id="circle-dashed-large_02" opacity="0.84">
              <path
                id="Vector_2"
                opacity="0.84"
                d="M130.956 446.903C157.499 483.421 208.62 491.509 245.139 464.966C281.658 438.423 289.745 387.302 263.202 350.783C236.66 314.265 185.538 306.177 149.02 332.72C112.501 359.263 104.414 410.384 130.956 446.903Z"
                stroke="#0080CB"
                stroke-width="4.01349"
                stroke-dasharray="2.01 2.01"
              />
            </g>
            <g id="circle-dashed-medium_02" opacity="0.7">
              <path
                id="Vector_3"
                opacity="0.7"
                d="M102.043 469.354C122.039 496.865 160.551 502.958 188.062 482.962C215.573 462.966 221.666 424.454 201.67 396.942C181.674 369.431 143.162 363.338 115.65 383.334C88.1391 403.33 82.0469 441.842 102.043 469.354Z"
                stroke="#0080CB"
                stroke-width="4.01349"
                stroke-dasharray="4.01 4.01"
              />
            </g>
            <g id="circle-dashed-small_02" opacity="0.5">
              <path
                id="Vector_4"
                opacity="0.5"
                d="M75.3313 496.693C87.7733 513.811 111.737 517.602 128.855 505.16C145.973 492.718 149.764 468.755 137.322 451.637C124.88 434.519 100.917 430.728 83.7985 443.17C66.6803 455.612 62.8894 479.575 75.3313 496.693Z"
                stroke="#0080CB"
                stroke-width="4.01349"
                stroke-dasharray="5.35 5.35"
              />
            </g>
            <g id="circle-large_02">
              <path
                id="Vector_5"
                d="M233.397 361.893C271.271 414.001 344.216 425.541 396.324 387.667C448.432 349.794 459.972 276.849 422.098 224.74C384.225 172.632 311.28 161.092 259.171 198.966C207.063 236.84 195.523 309.785 233.397 361.893Z"
                stroke="#0036CB"
                stroke-width="4.01349"
              />
            </g>
            <path
              id="line-long_02"
              d="M105.946 475.864L439.267 186.12"
              stroke="#0021C9"
              stroke-width="4.01485"
            />
            <g id="cross_02">
              <path
                id="Line 28"
                d="M342.082 144.133H365.645"
                stroke="#0021C9"
                stroke-width="4.01304"
              />
              <path
                id="Line 33"
                d="M353.864 131.805V155.368"
                stroke="#0021C9"
                stroke-width="4.01304"
              />
            </g>
            <path
              id="circle-line_02"
              d="M425.109 276.376L453.76 293.592"
              stroke="#0021C9"
              stroke-width="4.01304"
            />
            <path
              id="rect-large_02"
              d="M477.203 148.315H436.692V188.825H477.203V148.315Z"
              fill="#0021C9"
            />
            <path
              id="rect-medium_02"
              d="M426.721 148.315H404.284V169.505H426.721V148.315Z"
              fill="#0080CB"
            />
            <path
              id="rect-small_02"
              d="M511.481 200.667H494.031V219.365H511.481V200.667Z"
              fill="#0080CB"
            />
            <path
              id="rect-small-2_02"
              d="M312.668 258.628H294.594V277.325H312.668V258.628Z"
              fill="#0080CB"
            />
            <g id="arrow_02">
              <path
                id="Path 32"
                d="M511.975 104.317L549.998 122.004V133.286L511.975 150.974V138.903L538.483 127.667L511.975 116.385V104.317Z"
                fill="#0080CB"
                stroke="#0080CB"
                stroke-width="1.33768"
                stroke-miterlimit="10"
              />
            </g>
            <circle
              id="circle-filled-large_02"
              cx="-35"
              cy="-35"
              r="12"
              fill="#0080CB"
            />
            <circle
              id="circle-filled-medium_02"
              cx="20"
              cy="20"
              r="7"
              fill="#0080CB"
            />
            <path
              id="circle-filled-extralarge_02"
              d="M426.721 120.269C436.358 120.269 444.171 112.596 444.171 103.13C444.171 93.6646 436.358 85.9912 426.721 85.9912C417.083 85.9912 409.27 93.6646 409.27 103.13C409.27 112.596 417.083 120.269 426.721 120.269Z"
              fill="#0021C9"
            />
            <path
              id="line-1_02"
              d="M493.366 209.773H526.792"
              stroke="#0021C9"
              stroke-width="4.01304"
            />
            <line
              id="line-2_02"
              x1="0"
              x2="12"
              y1="-10"
              y2="-10"
              stroke="#0021C9"
              stroke-width="4"
            />
            <path
              id="line-3_02"
              d="M426.338 69V102.426"
              stroke="#00A2FF"
              stroke-width="4.01304"
            />
            <line
              id="line-4_02"
              x1="0"
              x2="0"
              y1="-20"
              y2="20"
              stroke="#0021C9"
              stroke-width="4"
            />
          </g>
        </svg>
      </div>
    </section>
  );
};

export default EthicsSection;
